import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Constant from './Constant';
import './Blogs.css'; // Ensure you have this CSS file

export default function Blog() {
    const [blogs, setBlogs] = useState([]); // Store blogs
    const [page, setPage] = useState(1); // Track current page
    const [loading, setLoading] = useState(false); // Loading state
    const [hasMore, setHasMore] = useState(true); // Check if more blogs exist

    useEffect(() => {
        fetchBlogs(page); // Load first batch of blogs on mount
    }, []);

    // Function to fetch blogs from API
    const fetchBlogs = async (pageNum) => {
        setLoading(true);
        try {
            const response = await fetch(`${Constant.Blogs}?page=${pageNum}`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();

            if (data.success && Array.isArray(data.data)) {
                setBlogs(prevBlogs => [...prevBlogs, ...data.data]); // Append new blogs
                setHasMore(data.data.length > 0); // Check if there are more blogs
            } else {
                setHasMore(false); // Stop loading if no more blogs
            }
        } catch (error) {
            console.error('Error fetching blog data:', error);
        }
        setLoading(false);
    };

    // Load more blogs when button is clicked
    const loadMore = () => {
        const nextPage = page + 1;
        setPage(nextPage);
        fetchBlogs(nextPage);
    };

    return (
        <>
            <div>
                {/* Header Section */}
                <div className="d-flex flex-column align-items-center justify-content-center blog-header">
                    <h1 className="display-3 font-weight-bold">Blogs</h1>
                    <div className="d-inline-flex">
                        <p className="m-0">
                            <Link className="text-dark" to="/">Home</Link>
                        </p>
                        <p className="m-0 px-2">/</p>
                        <p className="m-0 text-dark">Blog</p>
                    </div>
                </div>

                {/* Blog List Section */}
                <div className="container blog-container">
                    <div className="row">
                        {blogs.map((blog, index) => (
                            <div key={index} className="col-md-6 col-lg-4 mb-4">
                                <div className="blog-card">
                                    <Link to={`/blog_details/${blog.slug}`} className="text-decoration-none text-dark">
                                        <div className="blog-image">
                                            <img src={blog.image} alt={blog.title} className="img-fluid rounded" />
                                        </div>
                                        <div className="blog-content p-3">
                                            <h3 className="blog-title">{blog.title}</h3>
                                            <p
                                                className="blog-description"
                                                dangerouslySetInnerHTML={{ __html: blog.description }}
                                            />
                                        </div>
                                    </Link>
                                    <div className="text-center mt-3">
                                        <Link to={`/blog_details/${blog.slug}`} className="btn btn-primary">
                                            Read More
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Load More Button */}
                    {hasMore && (
                        <div className="text-center mt-4">
                            <button className="btn btn-primary btn-load-more" onClick={loadMore} disabled={loading}>
                                {loading ? 'Loading...' : 'Load More'}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}